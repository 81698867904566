<template>
  <div class="appla-home">
    <!-- 内容 -->
    <div class="content">
      <!-- 头部 -->
      <heads @goAnchor="goAnchor"></heads>

      <!-- 滚动区域 -->
      <div class="home" id="home">
        <el-carousel height="450px">
          <el-carousel-item v-for="item in carouselList" :key="item">
            <el-image
            :src="item"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="item" id="aboutUs">
        <div class="title-us">ABOUT US</div>
        <div class="title">- 关于我们 -</div>

        <div class="pt40">
          <div class="">
            <div class="fw600 f18 text-l">共享经济趋势新风口</div>
            <div class='pt20 text-l f14 sh-content'>
              爱律达(广州)咨询有限公司，成立于2023年，是一家AR智能娱乐软硬件研发技术与服务平台，公司将AR内容与实体硬件相结合，为景区、商场、主题乐园等地摊地推经济提供虚拟结合现实的新一代互动娱乐设备项目。
            </div>
          </div>



          <div class="pt20 h300 parent center">
            <div class="w100">
              <el-image
              class="box"
              style="height: 300px;width: 100%;"
              :src="imgList.aboutUs2"  fit="cover"></el-image>
            </div>
              <div class="pr10"></div>
            <!-- <div class="w50">
              <el-image
              class="box"
              style="height: 300px;width: 100%;"
              :src="imgList.aboutUs"  fit="cover"></el-image>
            </div> -->
            
          </div>
        </div>
      </div>
      <div class="item bg" id="youGin">
        <div class='title-us h-corporate-name-us'>Elida</div>
        <div class='title sh-corporate-name'>- 爱律达 -</div>

        <div class="pt40 parent w100">
          <div class="card box w25">
            <div class="f80"><i class="fa fa-group info2-color" ></i></div>
            <div class="f30 fw600">15000+</div>
            <div class="fw600">客户</div>
          </div>
          <div class="pr50"></div>
          <div class="card box w25">
            <div class="f80"><i class="fa fa-gamepad success-color"></i></div>
            <div class="f30 fw600">20+</div>
            <div class="fw600">游戏</div>
          </div>
          <div class="pr50"></div>
          <div class="card box w25">
            <div class="f80"><i class="fa fa-clock-o error-color"></i></div>
            <div class="f30 fw600">12000+</div>
            <div class="fw600">小时运营支持</div>
          </div>
          <div class="pr50"></div>
          <div class="card box w25">
            <div class="f80"><i class="fa fa-handshake-o warning-color"></i></div>
            <div class="f30 fw600">20+</div>
            <div class="fw600">运营</div>
          </div>
        </div>
      </div>
      <div class="item" id="service">
        <div class="title-us">SERVICE</div>
        <div class="title">- 优质服务 -</div>
        <div class="pt40 parent w100 center">
          <div>
            <div>
              <el-image
              class="box"
              style="height: 300px;width: 200px;"
              :src="imgList.service1" fit="cover"></el-image>
            </div>
            <div class="info-color">专业技术研发</div>
          </div>
          <div class="pr50"></div>
          <div>
            <div>
              <el-image
              class="box"
              style="height: 350px;width: 200px;"
            :src="imgList.service2" fit="cover"></el-image>
            </div>
            <div class="info-color">24小时专业服务</div>
          </div>
          <div class="pr50"></div>
          <div>
            <div>
              <el-image
              class="box"
              style="height: 300px;width: 200px;"
            :src="imgList.service3" fit="cover"></el-image>
            </div>
            <div class="info-color">智能收益后台</div>
          </div>
        </div>
      </div>
      <div class="item bg" id="advantage">
        <div class="title-us">STRENGTHS</div>
        <div class="title">- 我们的优势 -</div>
        <div class="pt40 parent w100 center">
          <div class="w25">
            <div class="f80"><i class="fa fa-black-tie warning-color"></i></div>
            <div class="fw600">自主研发</div>
          </div>
          <div class="w25">
            <div class="f80"><i class="fa fa-user-plus warning-color"></i></div>
            <div class="fw600">运营指导</div>
          </div>
          <div class="w25">
            <div class="f80"><i class="fa fa-volume-control-phone warning-color"></i></div>
            <div class="fw600">完美售后</div>
          </div>
          <div class="w25">
            <div class="f80"><i class="fa fa-skyatlas warning-color"></i></div>
            <div class="fw600">免费更新</div>
          </div>
        </div>
      </div>
      <div class="item" id="brand">
        <div class="title-us">PARTNER</div>
        <div class="title">- 合作品牌 -</div>
        <div class="pt40" style="padding: 20px;border-radius: 5px;">
        <div class="parent center">
            <el-image
            class="w33 h200 box"
            :src="imgList.cooperation7" fit="cover"></el-image>
            <div class="pr50"></div>
            <el-image
            class="w33 h200 box"
            :src="imgList.cooperation8" fit="cover"></el-image>
            <div class="pr50"></div>
            <el-image
            class="w33 h200 box"
            :src="imgList.cooperation9" fit="cover"></el-image>
          </div>
          <div class="parent pt20 center">
            <el-image
            class="w33 h200 box"
            :src="imgList.cooperation1" fit="cover"></el-image>
            <div class="pr50"></div>
            <el-image
            class="w33 h200 box"
            :src="imgList.cooperation2" fit="cover"></el-image>
            <div class="pr50"></div>
            <el-image
            class="w33 h200 box"
            :src="imgList.cooperation3" fit="cover"></el-image>
          </div>
           <div class="parent mt20 center">
            <el-image
            class="w33 h200 box"
            :src="imgList.cooperation4" fit="cover"></el-image>
            <div class="pr50"></div>
            <el-image
            class="w33 h200 box"
            :src="imgList.cooperation5" fit="cover"></el-image>
            <div class="pr50"></div>
            <el-image
            class="w33 h200 box"
            :src="imgList.cooperation6" fit="cover"></el-image>
          </div>

        </div>
      </div>

      <div class="item bg footer" id="contactUs">
        <div class="title-us">CONTACT US</div>
        <div class="title">- 联系我们 -</div>
        <div class="parent center pt30">
          <div class="h150 w30">
            <div class="fw600 f20">公司地址</div>
            <div class="f14 pt10">广州市天河区兴华路1号210房自编之一006房</div>
          </div>

          <div class="h150 w30">
            <div class="fw600 f20">服务热线</div>
            <div class="f14 pt10">15322291117</div>
          </div>

          <!-- <div class="h150 w30">
            <div class="fw600 f20">公众号</div>
            <div class="f14 pt10">
            <el-image
            style="width:100px;height: 100px;"
            :src="imgList.thePublic"></el-image>
            </div>
          </div> -->

        </div>
        <div class='text-c f14 copyright'>
          Copyright © 爱律达 <a href='https://beian.miit.gov.cn' target='_blank'>粤ICP备2022051809号</a>
        </div>
      </div>


    </div>
    
  </div>
</template>
<script>
// @ is an alias to /src cooperation
import heads from '@/components/heads.vue'
import pic1 from '@/assets/img/home/carousel/001.jpg'
import pic2 from '@/assets/img/home/carousel/002.jpg'
import pic3 from '@/assets/img/home/aboutUs.jpeg'
import pic4 from '@/assets/img/home/service-1.jpg'
import pic5 from '@/assets/img/home/service-2.jpg'
import pic6 from '@/assets/img/home/service-3.jpg'

import pic7 from '@/assets/img/home/cooperation/4.jpg'
import pic8 from '@/assets/img/home/cooperation/5.jpg'
import pic9 from '@/assets/img/home/cooperation/6.jpg'
import pic10 from '@/assets/img/home/cooperation/7.jpg'
import pic11 from '@/assets/img/home/cooperation/8.jpg'
import pic12 from '@/assets/img/home/cooperation/9.jpg'
import pic13 from '@/assets/img/home/cooperation/1.jpg'
import pic14 from '@/assets/img/home/cooperation/2.jpg'
import pic15 from '@/assets/img/home/cooperation/3.jpg'

import pic16 from '@/assets/img/home/the_public.jpg'
import pic17 from '@/assets/img/home/WechatIMG26.jpeg'
export default {
  components:{
    heads
  },
  data(){
    return{
        carouselList:[
          pic1,pic2
        ],
        imgList:{
          aboutUs:pic3,
          aboutUs2:pic17,
          service1:pic4,
          service2:pic5,
          service3:pic6,
          cooperation1:pic7,
          cooperation2:pic8,
          cooperation3:pic9,
          cooperation4:pic10,
          cooperation5:pic11,
          cooperation6:pic12,
          cooperation7:pic13,
          cooperation8:pic14,
          cooperation9:pic15,
          thePublic:pic16,
        }
    }
  },
  methods:{
    //模拟锚点跳转
    goAnchor(selector) {
        document.querySelector(selector).scrollIntoView({
            behavior: "smooth"
        });
    },
  }
}
</script>

<style lang="scss">
  .appla-home{
    width: 100%;
    .content{
      .home{
        padding-top: 50px;
        
      }
      .item{
        min-height: 200px;
        padding: 30px;
        .title-us{
          color: #DEE3FF;
          font-size: 25px;
          font-weight: 600;
        }
        .title{
          color: #0379FE;
          font-size: 18px;
        }
      }
    }
    .footer{
     padding: 30px;
     .copyright{
       a{
         text-decoration: none;
         background-color: transparent;
       }
     }
    }
  }
</style>