<template>
  <div class="heads parent">

      <div class="logo-bg w40">
        <!-- <el-image
        class="logo"
        :src="logoPic"></el-image> -->
        <span class="logo-text">爱律达</span>
      </div>

      <div class="w60 parent">
        <div class="nav" :class="{'active':linkActive=='home'}" @click="linkBtn('home')">首页</div>
        <div class="nav" :class="{'active':linkActive=='aboutUs'}" @click="linkBtn('aboutUs')">关于我们</div>
        <div class="nav" :class="{'active':linkActive=='youGin'}" @click="linkBtn('youGin')">爱律达</div>
        <div class="nav" :class="{'active':linkActive=='service'}" @click="linkBtn('service')">优质服务</div>
        <div class="nav" :class="{'active':linkActive=='advantage'}" @click="linkBtn('advantage')">我们的优势</div>
        <div class="nav" :class="{'active':linkActive=='brand'}" @click="linkBtn('brand')">合作品牌</div>
        <div class="nav" :class="{'active':linkActive=='contactUs'}" @click="linkBtn('contactUs')">联系我们</div>
      </div>
  </div>
</template>

<script>
import logoPic from '@/assets/img/logo.png'
export default {
  data(){
    return{
      logoPic:logoPic,
      linkActive:'home'
    }
  },
  methods:{
    // 滚动触发按钮高亮
    linkBtn(e){
      this.linkActive = e
      this.$emit('goAnchor','#'+e)
    },
  }
}
</script>

<style scoped lang="scss">
  .heads{
    position: fixed;
    width: 1200px;
    //width:100%;
    height: 50px;
    background-color: #fff;
    font-size: 14px;
    z-index: 99;
    //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    .nav{
      min-width: 80px;
      margin-left: 6px;
      padding-top: 12px;
      font-weight: 500;
      cursor: pointer;
      &:hover{
        color: #4E73ED;
      }
    }
    .active{
      padding-top: 8px;
      border-top: 4px solid #0544ED;
      border-left: 1px solid #F2F2F2;
      border-right: 1px solid #F2F2F2;
      color: #4E73ED;
    }
    .logo-bg{
      text-align: center;
      padding-top: 6px;
      .logo{
        width: 170px;
      }
      .logo-text{
        font-size: 28px;
        font-weight: 500;
        color: #4E73ED;
      }
    }
    
    
  }
</style>
